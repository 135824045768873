<template>
  <div class="pa-0">
    <div class="mt-15 px-8" v-if="isLoad || isLoadLaporanKinerja">
      <v-row class="px-15">
        <v-col cols="12" v-for="i in 5" :key="i" class="text-left">
          <v-skeleton-loader type="paragraph" class="mt-3"></v-skeleton-loader>
        </v-col>
      </v-row>
    </div>
    <div class="grid justify-items-center px-0 py-10" v-else>
      <v-col cols="11" class="pa-0 mt-10 pt-10">
        <v-row class="px-3">
          <v-col cols="12" class="text-left mb-4">
            <span class="font-bold text-3xl text-capitalize" v-if="bahasa"
              >Laporan</span
            >
            <span class="font-bold text-3xl text-capitalize" v-else
              >Reports</span
            >
          </v-col>
        </v-row>
        <v-tabs
          v-model="tab"
          class="stories mt-3 max-w-md md:max-w-7xl"
          show-arrows>
          <v-tab href="#tab-1" class="">
            <span v-if="bahasa">Semua Laporan</span>
            <span v-else>All Reports</span>
          </v-tab>
          <v-tab href="#tab-2" class="">
            <span v-if="bahasa">Laporan Kinerja</span>
            <span v-else>Performance Reports</span>
          </v-tab>
          <v-tab href="#tab-3" class="">
            <span v-if="bahasa">Laporan Tahunan</span>
            <span v-else>Annual Reports</span>
          </v-tab>
          <v-tab href="#tab-4" class="">
            <span v-if="bahasa">Laporan Keuangan</span>
            <span v-else>Financial Statements</span>
          </v-tab>
          <v-tab href="#tab-5" class="">
            <span v-if="bahasa">Impact Report Proyek</span>
            <span v-else>Impact Reports Project</span>
          </v-tab>
          <v-tab href="#tab-6" class="">
            <span v-if="bahasa">Laporan Lainnya</span>
            <span v-else>Other Reports</span>
          </v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab">
          <v-tab-item value="tab-1" class="pa-0">
            <v-row class="mt-3" v-if="isLoad">
              <v-col
                cols="12"
                sm="6"
                class="text-left pa-6 cursor-pointer"
                v-for="i in 4"
                :key="i">
                <v-skeleton-loader
                  type="paragraph"
                  class="mt-5"></v-skeleton-loader>
              </v-col>
            </v-row>
            <v-row class="mt-3" v-else-if="!isLoad && itemList.data.length > 0">
              <v-col
                cols="12"
                sm="4"
                v-for="(i, index) in itemList.data"
                :key="index"
                class="pa-6 text-left cursor-pointer"
                @click="goTo('report-detail', i.id)">
                <img
                  :src="baseUrlImage + i.image.file_path"
                  @error="
                    $event.target.src =
                      'https://admin.bpdlh.id/assets/empty.png'
                  "
                  class="rounded-md max-w-sm" />
                <div class="font-bold text-2xl sm:text-2xl pa-0 mt-5 h-14">
                  {{ i.title || "-" }}
                </div>
                <div class="text-sm py-2 pa-0">{{ i.date || "-" }}</div>
                <div class="font-lora pa-0 text-base h-24 sm:h-32">
                  <div v-html="strLimit(i.desc, 200)" />
                </div>
                <v-btn
                  class="btn-primary-outlined my-8 font-bold text-capitalize">
                  <span v-if="bahasa">baca selengkapnya</span>
                  <span v-else>Read More</span>
                </v-btn>
              </v-col>
            </v-row>
            <v-row class="text-left mt-3 py-5" v-if="itemList.data.length > 0">
              <v-spacer />
              <v-col cols="auto">
                <v-pagination
                  v-model="page"
                  :length="pageCount"
                  color="green darken-1"
                  next-icon="navigate_next"
                  prev-icon="chevron_left"></v-pagination>
              </v-col>
            </v-row>
            <v-row v-else>
              <v-col cols="12" sm="6">
                <img src="@/assets/images/405.png" width="100%" />
              </v-col>
              <v-col
                cols="12"
                sm="6"
                class="text-black flex flex-wrap content-center">
                <v-col cols="12" class="text-3xl font-bold pl-0">{{
                  bahasa ? "Tidak Ada Data yang Tersedia" : "No Data Available"
                }}</v-col>
                <v-btn class="btn-primary mt-8" :to="{ name: 'home' }">
                  {{ bahasa ? "kembali ke beranda" : "back to home" }}
                </v-btn>
              </v-col></v-row
            >
          </v-tab-item>
          <v-tab-item value="tab-2" class="pa-0">
            <v-row class="mt-3" v-if="isLoadLaporanKinerja">
              <v-col
                cols="12"
                sm="6"
                class="text-left pa-6 cursor-pointer"
                v-for="i in 4"
                :key="i">
                <v-skeleton-loader
                  type="paragraph"
                  class="mt-5"></v-skeleton-loader>
              </v-col>
            </v-row>
            <v-row
              class="mt-3"
              v-else-if="
                !isLoadLaporanKinerja && itemListLaporanKinerja.data.length > 0
              ">
              <v-col
                cols="12"
                sm="4"
                v-for="(i, index) in itemListLaporanKinerja.data"
                :key="index"
                class="pa-6 text-left cursor-pointer"
                @click="goTo('report-detail', i.id)">
                <img
                  :src="baseUrlImage + i.image.file_path"
                  @error="
                    $event.target.src =
                      'https://admin.bpdlh.id/assets/empty.png'
                  "
                  class="rounded-md max-w-sm" />
                <div class="font-bold text-2xl sm:text-2xl pa-0 mt-5 h-14">
                  {{ i.title || "-" }}
                </div>
                <div class="text-sm py-2 pa-0">{{ i.date || "-" }}</div>
                <div class="font-lora pa-0 text-base h-24 sm:h-32">
                  <div v-html="strLimit(i.desc, 200)" />
                </div>
                <v-btn
                  class="btn-primary-outlined my-8 font-bold text-capitalize">
                  <span v-if="bahasa">baca selengkapnya</span>
                  <span v-else>Read More</span>
                </v-btn>
              </v-col>
            </v-row>
            <v-row
              class="text-left mt-3 py-5"
              v-if="itemListLaporanKinerja.data.length > 0">
              <v-spacer />
              <v-col cols="auto">
                <v-pagination
                  v-model="pageLaporanKinerja"
                  :length="pageCountLaporanKinerja"
                  color="green darken-1"
                  next-icon="navigate_next"
                  prev-icon="chevron_left"></v-pagination>
              </v-col>
            </v-row>
            <v-row v-else>
              <v-col cols="12" sm="6">
                <img src="@/assets/images/405.png" width="100%" />
              </v-col>
              <v-col
                cols="12"
                sm="6"
                class="text-black flex flex-wrap content-center">
                <v-col cols="12" class="text-3xl font-bold pl-0">{{
                  bahasa ? "Tidak Ada Data yang Tersedia" : "No Data Available"
                }}</v-col>
                <v-btn class="btn-primary mt-8" :to="{ name: 'home' }">
                  {{ bahasa ? "kembali ke beranda" : "back to home" }}
                </v-btn>
              </v-col></v-row
            >
          </v-tab-item>
          <v-tab-item value="tab-3" class="pa-0">
            <v-row class="mt-3" v-if="isLoadLaporanTahunan">
              <v-col
                cols="12"
                sm="6"
                class="text-left pa-6 cursor-pointer"
                v-for="i in 4"
                :key="i">
                <v-skeleton-loader
                  type="paragraph"
                  class="mt-5"></v-skeleton-loader>
              </v-col>
            </v-row>
            <v-row
              class="mt-3"
              v-else-if="
                !isLoadLaporanTahunan && itemListLaporanTahunan.data.length > 0
              ">
              <v-col
                cols="12"
                sm="4"
                v-for="(i, index) in itemListLaporanTahunan.data"
                :key="index"
                class="pa-6 text-left cursor-pointer"
                @click="goTo('report-detail', i.id)">
                <img
                  :src="baseUrlImage + i.image.file_path"
                  @error="
                    $event.target.src =
                      'https://admin.bpdlh.id/assets/empty.png'
                  "
                  class="rounded-md max-w-sm" />
                <div class="font-bold text-2xl sm:text-2xl pa-0 mt-5 h-14">
                  {{ i.title || "-" }}
                </div>
                <div class="text-sm py-2 pa-0">{{ i.date || "-" }}</div>
                <div class="font-lora pa-0 text-base h-24 sm:h-32">
                  <div v-html="strLimit(i.desc, 200)" />
                </div>
                <v-btn
                  class="btn-primary-outlined my-8 font-bold text-capitalize">
                  <span v-if="bahasa">baca selengkapnya</span>
                  <span v-else>Read More</span>
                </v-btn>
              </v-col>
            </v-row>
            <v-row
              class="text-left mt-3 py-5"
              v-if="itemListLaporanTahunan.data.length > 0">
              <v-spacer />
              <v-col cols="auto">
                <v-pagination
                  v-model="pageLaporanTahunan"
                  :length="pageCountLaporanTahunan"
                  color="green darken-1"
                  next-icon="navigate_next"
                  prev-icon="chevron_left"></v-pagination>
              </v-col>
            </v-row>
            <v-row v-else>
              <v-col cols="12" sm="6">
                <img src="@/assets/images/405.png" width="100%" />
              </v-col>
              <v-col
                cols="12"
                sm="6"
                class="text-black flex flex-wrap content-center">
                <v-col cols="12" class="text-3xl font-bold pl-0">{{
                  bahasa ? "Tidak Ada Data yang Tersedia" : "No Data Available"
                }}</v-col>
                <v-btn class="btn-primary mt-8" :to="{ name: 'home' }">
                  {{ bahasa ? "kembali ke beranda" : "back to home" }}
                </v-btn>
              </v-col></v-row
            >
          </v-tab-item>
          <v-tab-item value="tab-4" class="pa-0">
            <v-row class="mt-3" v-if="isLoadLaporanKeuangan">
              <v-col
                cols="12"
                sm="6"
                class="text-left pa-6 cursor-pointer"
                v-for="i in 4"
                :key="i">
                <v-skeleton-loader
                  type="paragraph"
                  class="mt-5"></v-skeleton-loader>
              </v-col>
            </v-row>
            <v-row
              class="mt-3"
              v-else-if="
                !isLoadLaporanKeuangan &&
                itemListLaporanKeuangan.data.length > 0
              ">
              <v-col
                cols="12"
                sm="4"
                v-for="(i, index) in itemListLaporanKeuangan.data"
                :key="index"
                class="pa-6 text-left cursor-pointer"
                @click="goTo('report-detail', i.id)">
                <img
                  :src="baseUrlImage + i.image.file_path"
                  @error="
                    $event.target.src =
                      'https://admin.bpdlh.id/assets/empty.png'
                  "
                  class="rounded-md max-w-sm" />
                <div class="font-bold text-2xl sm:text-2xl pa-0 mt-5 h-14">
                  {{ i.title || "-" }}
                </div>
                <div class="text-sm py-2 pa-0">{{ i.date || "-" }}</div>
                <div class="font-lora pa-0 text-base h-24 sm:h-32">
                  <div v-html="strLimit(i.desc, 200)" />
                </div>
                <v-btn
                  class="btn-primary-outlined my-8 font-bold text-capitalize">
                  <span v-if="bahasa">baca selengkapnya</span>
                  <span v-else>Read More</span>
                </v-btn>
              </v-col>
            </v-row>
            <v-row
              class="text-left mt-3 py-5"
              v-if="itemListLaporanKeuangan.data.length > 0">
              <v-spacer />
              <v-col cols="auto">
                <v-pagination
                  v-model="pageLaporanKeuangan"
                  :length="pageCountLaporanKeuangan"
                  color="green darken-1"
                  next-icon="navigate_next"
                  prev-icon="chevron_left"></v-pagination>
              </v-col>
            </v-row>
            <v-row v-else>
              <v-col cols="12" sm="6">
                <img src="@/assets/images/405.png" width="100%" />
              </v-col>
              <v-col
                cols="12"
                sm="6"
                class="text-black flex flex-wrap content-center">
                <v-col cols="12" class="text-3xl font-bold pl-0">{{
                  bahasa ? "Tidak Ada Data yang Tersedia" : "No Data Available"
                }}</v-col>
                <v-btn class="btn-primary mt-8" :to="{ name: 'home' }">
                  {{ bahasa ? "kembali ke beranda" : "back to home" }}
                </v-btn>
              </v-col></v-row
            >
          </v-tab-item>
          <v-tab-item value="tab-5" class="pa-0">
            <v-row class="mt-3" v-if="isLoadProyek">
              <v-col
                cols="12"
                sm="6"
                class="text-left pa-6 cursor-pointer"
                v-for="i in 4"
                :key="i">
                <v-skeleton-loader
                  type="paragraph"
                  class="mt-5"></v-skeleton-loader>
              </v-col>
            </v-row>
            <v-row
              class="mt-3"
              v-else-if="!isLoadProyek && itemListProyek.data.length > 0">
              <v-col
                cols="12"
                sm="4"
                v-for="(i, index) in itemListProyek.data"
                :key="index"
                class="pa-6 text-left cursor-pointer"
                @click="goTo('report-detail', i.id)">
                <img
                  :src="baseUrlImage + i.image.file_path"
                  @error="
                    $event.target.src =
                      'https://admin.bpdlh.id/assets/empty.png'
                  "
                  class="rounded-md max-w-sm" />
                <div class="font-bold text-2xl sm:text-2xl pa-0 mt-5 h-14">
                  {{ i.title || "-" }}
                </div>
                <div class="text-sm py-2 pa-0">{{ i.date || "-" }}</div>
                <div class="font-lora pa-0 text-base h-24 sm:h-32">
                  <div v-html="strLimit(i.desc, 200)" />
                </div>
                <v-btn
                  class="btn-primary-outlined my-8 font-bold text-capitalize">
                  <span v-if="bahasa">baca selengkapnya</span>
                  <span v-else>Read More</span>
                </v-btn>
              </v-col>
            </v-row>
            <v-row
              class="text-left mt-3 py-5"
              v-if="itemListProyek.data.length > 0">
              <v-spacer />
              <v-col cols="auto">
                <v-pagination
                  v-model="pageProyek"
                  :length="pageCountProyek"
                  color="green darken-1"
                  next-icon="navigate_next"
                  prev-icon="chevron_left"></v-pagination>
              </v-col>
            </v-row>
            <v-row v-else>
              <v-col cols="12" sm="6">
                <img src="@/assets/images/405.png" width="100%" />
              </v-col>
              <v-col
                cols="12"
                sm="6"
                class="text-black flex flex-wrap content-center">
                <v-col cols="12" class="text-3xl font-bold pl-0">{{
                  bahasa ? "Tidak Ada Data yang Tersedia" : "No Data Available"
                }}</v-col>
                <v-btn class="btn-primary mt-8" :to="{ name: 'home' }">
                  {{ bahasa ? "kembali ke beranda" : "back to home" }}
                </v-btn>
              </v-col></v-row
            >
          </v-tab-item>
          <v-tab-item value="tab-6" class="pa-0">
            <v-row class="mt-3" v-if="isLoadLaporanLainnya">
              <v-col
                cols="12"
                sm="6"
                class="text-left pa-6 cursor-pointer"
                v-for="i in 4"
                :key="i">
                <v-skeleton-loader
                  type="paragraph"
                  class="mt-5"></v-skeleton-loader>
              </v-col>
            </v-row>
            <v-row
              class="mt-3"
              v-else-if="
                !isLoadLaporanLainnya && itemListLaporanLainnya.data.length > 0
              ">
              <v-col
                cols="12"
                sm="4"
                v-for="(i, index) in itemListLaporanLainnya.data"
                :key="index"
                class="pa-6 text-left cursor-pointer"
                @click="goTo('report-detail', i.id)">
                <img
                  :src="baseUrlImage + i.image.file_path"
                  @error="
                    $event.target.src =
                      'https://admin.bpdlh.id/assets/empty.png'
                  "
                  class="rounded-md max-w-sm" />
                <div class="font-bold text-2xl sm:text-2xl pa-0 mt-5 h-14">
                  {{ i.title || "-" }}
                </div>
                <div class="text-sm py-2 pa-0">{{ i.date || "-" }}</div>
                <div class="font-lora pa-0 text-base h-24 sm:h-32">
                  <div v-html="strLimit(i.desc, 200)" />
                </div>
                <v-btn
                  class="btn-primary-outlined my-8 font-bold text-capitalize">
                  <span v-if="bahasa">baca selengkapnya</span>
                  <span v-else>Read More</span>
                </v-btn>
              </v-col>
            </v-row>
            <v-row
              class="text-left mt-3 py-5"
              v-if="itemListLaporanLainnya.data.length > 0">
              <v-spacer />
              <v-col cols="auto">
                <v-pagination
                  v-model="pageLaporanLainnya"
                  :length="pageCountLaporanLainnya"
                  color="green darken-1"
                  next-icon="navigate_next"
                  prev-icon="chevron_left"></v-pagination>
              </v-col>
            </v-row>
            <v-row v-else>
              <v-col cols="12" sm="6">
                <img src="@/assets/images/405.png" width="100%" />
              </v-col>
              <v-col
                cols="12"
                sm="6"
                class="text-black flex flex-wrap content-center">
                <v-col cols="12" class="text-3xl font-bold pl-0">{{
                  bahasa ? "Tidak Ada Data yang Tersedia" : "No Data Available"
                }}</v-col>
                <v-btn class="btn-primary mt-8" :to="{ name: 'home' }">
                  {{ bahasa ? "kembali ke beranda" : "back to home" }}
                </v-btn>
              </v-col></v-row
            >
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";

export default {
  props: {
    bahasa: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      page: 1,
      pageLaporanKinerja: 1,
      pageLaporanTahunan: 1,
      pageLaporanKeuangan: 1,
      pageLaporanLainnya: 1,
      pageProyek: 1,
      pageCount: 1,
      pageCountLaporanKinerja: 1,
      pageCountLaporanTahunan: 1,
      pageCountLaporanKeuangan: 1,
      pageCountLaporanLainnya: 1,
      pageCountProyek: 1,
      tab: null,
      isFiltered: false,
      itemList: [],
      itemListLaporanKinerja: [],
      itemListLaporanTahunan: [],
      itemListLaporanKeuangan: [],
      itemListLaporanLainnya: [],
      itemListProyek: [],
      isLoad: false,
      isLoadLaporanKinerja: false,
      isLoadLaporanTahunan: false,
      isLoadLaporanKeuangan: false,
      isLoadLaporanLainnya: false,
      isLoadProyek: false,
      baseUrl: process.env.VUE_APP_BASE_URL,
      baseUrlImage: process.env.VUE_APP_BASE_ASSET,
    };
  },
  computed: {
    pageName() {
      let path = this.$route.name;
      let page = path.split("/").join("");
      return page.includes("-") ? page.split("-").join(" ") : page;
    },
    language() {
      return this.bahasa ? "ID" : "ENG";
    },
  },
  watch: {
    bahasa() {
      this.getData();
      this.getDataLaporanKinerja();
      this.getDataLaporanTahunan();
      this.getDataLaporanKeuangan();
      this.getDataLaporanLainnya();
      this.getDataProyek();
    },
    page() {
      this.getData();
    },
    pageLaporanKinerja() {
      this.getDataLaporanKinerja();
    },
    pageLaporanTahunan() {
      this.getDataLaporanTahunan();
    },
    pageLaporanKeuangan() {
      this.getDataLaporanKeuangan();
    },
    pageLaporanLainnya() {
      this.getDataLaporanLainnya();
    },
    pageProyek() {
      this.getDataProyek();
    },
  },
  mounted() {
    this.getData();
    this.getDataLaporanKinerja();
    this.getDataLaporanTahunan();
    this.getDataLaporanKeuangan();
    this.getDataLaporanLainnya();
    this.getDataProyek();
  },
  methods: {
    goTo(link, param) {
      this.$router.push({
        name: link,
        params: {
          id: param,
        },
      });
    },
    // Get All Data
    async getData() {
      this.isLoad = true;
      await axios
        .get(this.baseUrl + "/report", {
          params: { lang: this.language, page: this.page },
        })
        .then((response) => {
          this.itemList = response.data.data;
          this.page = response.data.data.current_page;
          this.pageCount = response.data.data.last_page;
        })
        .catch((error) => {
          Swal.fire("Failed!", error.response.data.message, "error");
        })
        .finally(() => (this.isLoad = false));
    },

    // Get Laporan Kinerja
    async getDataLaporanKinerja() {
      this.isLoadLaporanKinerja = true;
      let param = {
        lang: this.language,
        page: this.pageLaporanKinerja,
        category: "1",
        per_page: this.per_page,
      };
      await axios
        .get(this.baseUrl + "/report", {
          params: param,
        })
        .then((response) => {
          this.itemListLaporanKinerja = response.data.data;
          this.pageLaporanKinerja = response.data.data.current_page;
          this.pageCountLaporanKinerja = response.data.data.last_page;
        })
        .catch((error) => {
          Swal.fire("Failed!", error.response.data.message, "error");
        })
        .finally(() => (this.isLoadLaporanKinerja = false));
    },

    // Get Laporan Tahunan
    async getDataLaporanTahunan() {
      this.isLoadLaporanTahunan = true;
      let param = {
        lang: this.language,
        page: this.pageLaporanTahunan,
        category: "2",
        per_page: this.per_page,
      };
      await axios
        .get(this.baseUrl + "/report", {
          params: param,
        })
        .then((response) => {
          this.itemListLaporanTahunan = response.data.data;
          this.pageLaporanTahunan = response.data.data.current_page;
          this.pageCountLaporanTahunan = response.data.data.last_page;
        })
        .catch((error) => {
          Swal.fire("Failed!", error.response.data.message, "error");
        })
        .finally(() => (this.isLoadLaporanTahunan = false));
    },

    // Get Laporan Keuangan
    async getDataLaporanKeuangan() {
      this.isLoadLaporanKeuangan = true;
      let param = {
        lang: this.language,
        page: this.pageLaporanKeuangan,
        category: "3",
        per_page: this.per_page,
      };
      await axios
        .get(this.baseUrl + "/report", {
          params: param,
        })
        .then((response) => {
          this.itemListLaporanKeuangan = response.data.data;
          this.pageLaporanKeuangan = response.data.data.current_page;
          this.pageCountLaporanKeuangan = response.data.data.last_page;
        })
        .catch((error) => {
          Swal.fire("Failed!", error.response.data.message, "error");
        })
        .finally(() => (this.isLoadLaporanKeuangan = false));
    },

    // Get Laporan Lainnya
    async getDataLaporanLainnya() {
      this.isLoadLaporanLainnya = true;
      let param = {
        lang: this.language,
        page: this.pageLaporanLainnya,
        category: "5",
        per_page: this.per_page,
      };
      await axios
        .get(this.baseUrl + "/report", {
          params: param,
        })
        .then((response) => {
          this.itemListLaporanLainnya = response.data.data;
          this.pageLaporanLainnya = response.data.data.current_page;
          this.pageCountLaporanLainnya = response.data.data.last_page;
        })
        .catch((error) => {
          Swal.fire("Failed!", error.response.data.message, "error");
        })
        .finally(() => (this.isLoadLaporanLainnya = false));
    },

    // Get Laporan Keuangan
    async getDataProyek() {
      this.isLoadProyek = true;
      let param = {
        lang: this.language,
        page: this.pageProyek,
        category: "4",
        per_page: this.per_page,
      };
      await axios
        .get(this.baseUrl + "/report", {
          params: param,
        })
        .then((response) => {
          this.itemListProyek = response.data.data;
          this.pageProyek = response.data.data.current_page;
          this.pageCountProyek = response.data.data.last_page;
        })
        .catch((error) => {
          Swal.fire("Failed!", error.response.data.message, "error");
        })
        .finally(() => (this.isLoadProyek = false));
    },
  },
};
</script>
